<template>
  <div>
    <nav
      class="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow"
    >
      <div class="navbar-container d-flex content">
        <div class="bookmark-wrapper d-flex align-items-center">
          <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
              <a class="nav-link menu-toggle" href="javascript:void(0);"
                ><i class="ficon" data-feather="menu"></i
              ></a>
            </li>
          </ul>
        </div>
        <ul class="nav navbar-nav align-items-center ml-auto">
          <li class="nav-item dropdown dropdown-notification mr-25">
            <a
              class="nav-link"
              href="javascript:void(0);"
              data-toggle="dropdown"
              ><i class="ficon" data-feather="bell"></i
              ><span class="badge badge-pill badge-danger badge-up">5</span></a
            >
            <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex">
                  <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
                  <div class="badge badge-pill badge-light-primary">6 New</div>
                </div>
              </li>
              <li class="scrollable-container media-list">
                <a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar">
                        <img
                          src="app-assets/images/portrait/small/avatar-s-15.jpg"
                          alt="avatar"
                          width="32"
                          height="32"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder"
                          >Congratulation Sam 🎉</span
                        >winner!
                      </p>
                      <small class="notification-text">
                        Won the monthly best seller badge.</small
                      >
                    </div>
                  </div> </a
                ><a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar">
                        <img
                          src="/app-assets/images/portrait/small/avatar-s-3.jpg"
                          alt="avatar"
                          width="32"
                          height="32"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder">New message</span
                        >&nbsp;received
                      </p>
                      <small class="notification-text">
                        You have 10 unread messages</small
                      >
                    </div>
                  </div> </a
                ><a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar bg-light-danger">
                        <div class="avatar-content">MD</div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder">Revised Order 👋</span
                        >&nbsp;checkout
                      </p>
                      <small class="notification-text">
                        MD Inc. order updated</small
                      >
                    </div>
                  </div>
                </a>
                <div class="media d-flex align-items-center">
                  <h6 class="font-weight-bolder mr-auto mb-0">
                    System Notifications
                  </h6>
                  <div
                    class="custom-control custom-control-primary custom-switch"
                  >
                    <input
                      class="custom-control-input"
                      id="systemNotification"
                      type="checkbox"
                      checked=""
                    />
                    <label
                      class="custom-control-label"
                      for="systemNotification"
                    ></label>
                  </div>
                </div>
                <a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar bg-light-danger">
                        <div class="avatar-content">
                          <i class="avatar-icon" data-feather="x"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder">Server down</span
                        >&nbsp;registered
                      </p>
                      <small class="notification-text">
                        USA Server is down due to hight CPU usage</small
                      >
                    </div>
                  </div> </a
                ><a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar bg-light-success">
                        <div class="avatar-content">
                          <i class="avatar-icon" data-feather="check"></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder">Sales report</span
                        >&nbsp;generated
                      </p>
                      <small class="notification-text">
                        Last month sales report generated</small
                      >
                    </div>
                  </div> </a
                ><a class="d-flex" href="javascript:void(0)">
                  <div class="media d-flex align-items-start">
                    <div class="media-left">
                      <div class="avatar bg-light-warning">
                        <div class="avatar-content">
                          <i
                            class="avatar-icon"
                            data-feather="alert-triangle"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div class="media-body">
                      <p class="media-heading">
                        <span class="font-weight-bolder">High memory</span
                        >&nbsp;usage
                      </p>
                      <small class="notification-text">
                        BLR Server using high memory</small
                      >
                    </div>
                  </div>
                </a>
              </li>
              <li class="dropdown-menu-footer">
                <a class="btn btn-primary btn-block" href="javascript:void(0)"
                  >Read all notifications</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown dropdown-user">
            <a
              class="nav-link dropdown-toggle dropdown-user-link"
              id="dropdown-user"
              href="javascript:void(0);"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="user-nav d-sm-flex d-none">
                <span class="user-name font-weight-bolder">{{
                  user.name
                }}</span>

                <span class="user-status">Responsable </span>
              </div>
              <span class="avatar"
                ><img
                  class="round"
                  src="/app-assets/images/portrait/small/avatar-s-11.jpg"
                  alt="avatar"
                  height="40"
                  width="40" /><span class="avatar-status-online"></span
              ></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdown-user"
            >
              <a class="dropdown-item" href="#">
                <i class="mr-50" data-feather="user"></i> Profile</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click.prevent="logout()"
                ><i class="mr-50" data-feather="power"></i> Logout</a
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <ul class="main-search-list-defaultlist d-none">
      <li class="d-flex align-items-center">
        <a href="javascript:void(0);">
          <h6 class="section-label mt-75 mb-0">Files</h6>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between w-100"
          href="app-file-manager.html"
        >
          <div class="d-flex">
            <div class="mr-75">
              <img
                src="app-assets/images/icons/xls.png"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">Two new item submitted</p>
              <small class="text-muted">Marketing Manager</small>
            </div>
          </div>
          <small class="search-data-size mr-50 text-muted">&apos;17kb</small>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between w-100"
          href="app-file-manager.html"
        >
          <div class="d-flex">
            <div class="mr-75">
              <img
                src="app-assets/images/icons/jpg.png"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">52 JPG file Generated</p>
              <small class="text-muted">FontEnd Developer</small>
            </div>
          </div>
          <small class="search-data-size mr-50 text-muted">&apos;11kb</small>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between w-100"
          href="app-file-manager.html"
        >
          <div class="d-flex">
            <div class="mr-75">
              <img
                src="app-assets/images/icons/pdf.png"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">25 PDF File Uploaded</p>
              <small class="text-muted">Digital Marketing Manager</small>
            </div>
          </div>
          <small class="search-data-size mr-50 text-muted">&apos;150kb</small>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between w-100"
          href="app-file-manager.html"
        >
          <div class="d-flex">
            <div class="mr-75">
              <img
                src="app-assets/images/icons/doc.png"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">Anna_Strong.doc</p>
              <small class="text-muted">Web Designer</small>
            </div>
          </div>
          <small class="search-data-size mr-50 text-muted">&apos;256kb</small>
        </a>
      </li>
      <li class="d-flex align-items-center">
        <a href="javascript:void(0);">
          <h6 class="section-label mt-75 mb-0">Members</h6>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between py-50 w-100"
          href="app-user-view.html"
        >
          <div class="d-flex align-items-center">
            <div class="avatar mr-75">
              <img
                src="app-assets/images/portrait/small/avatar-s-8.jpg"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">John Doe</p>
              <small class="text-muted">UI designer</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between py-50 w-100"
          href="app-user-view.html"
        >
          <div class="d-flex align-items-center">
            <div class="avatar mr-75">
              <img
                src="app-assets/images/portrait/small/avatar-s-1.jpg"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">Michal Clark</p>
              <small class="text-muted">FontEnd Developer</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between py-50 w-100"
          href="app-user-view.html"
        >
          <div class="d-flex align-items-center">
            <div class="avatar mr-75">
              <img
                src="app-assets/images/portrait/small/avatar-s-14.jpg"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">Milena Gibson</p>
              <small class="text-muted">Digital Marketing Manager</small>
            </div>
          </div>
        </a>
      </li>
      <li class="auto-suggestion">
        <a
          class="d-flex align-items-center justify-content-between py-50 w-100"
          href="app-user-view.html"
        >
          <div class="d-flex align-items-center">
            <div class="avatar mr-75">
              <img
                src="app-assets/images/portrait/small/avatar-s-6.jpg"
                alt="png"
                height="32"
              />
            </div>
            <div class="search-data">
              <p class="search-data-title mb-0">Anna Strong</p>
              <small class="text-muted">Web Designer</small>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <ul class="main-search-list-defaultlist-other-list d-none">
      <li class="auto-suggestion justify-content-between">
        <a
          class="d-flex align-items-center justify-content-between w-100 py-50"
        >
          <div class="d-flex justify-content-start">
            <span class="mr-75" data-feather="alert-circle"></span
            ><span>No results found.</span>
          </div>
        </a>
      </li>
    </ul>
    <!-- END: Header-->

    <!-- BEGIN: Main Menu-->
    <div
      class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
      data-scroll-to-active="true"
    >
      <div class="navbar-header">
        <ul class="nav navbar-nav flex-row" >
          <li class="nav-item mr-auto" >
        
                <img
                  class="img-fluid brand-text"
                  src="/assets/logo.png"
                  alt="Login V2"
                  style="width: 100px;margin-left: 45px;cursor: pointer;margin-top: -15px;"
                />
            
              <!-- <h2 class="brand-text">Vuexy</h2> -->
          </li>
          <li class="nav-item nav-toggle">
            <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
              ><i
                class="d-block d-xl-none text-primary toggle-icon font-medium-4"
                data-feather="x"
              ></i
              ><i
                class="d-none d-xl-block collapse-toggle-icon font-medium-4 text-primary"
                data-feather="disc"
                data-ticon="disc"
              ></i
            ></a>
          </li>
        </ul>
      </div>
      <div class="main-menu-content" style="margin-top:15px;">
        <ul
          class="navigation navigation-main"
          id="main-menu-navigation"
          data-menu="menu-navigation"
        >
          <li class="nav-item">
            <router-link class="d-flex align-items-center " to="/dashboard">
              <i class="mdi mdi-home icone-layout"> </i>
              <span class="menu-title text-truncate" data-i18n="Dashboards"
                >Dashboards
              </span>
            </router-link>
          </li>
          <li
            class="navigation-header"
           v-if="testRole('profil delegue')"
          >
            <span data-i18n="Apps &amp; Pages">délégué</span>
          </li>
          <li class="nav-item" v-if="testRole('profil delegue')">
            <router-link class="d-flex align-items-center"  to="/profildelegue">
              <i class="mdi mdi-medical-bag  icone-layout"> </i
              ><span class="menu-title text-truncate"
                >liste des visites</span
              >
            </router-link>
          </li>
          
 
          <li class="navigation-header" v-if="testRole('gestion des zones')">
            <span data-i18n="Apps &amp; Pages">Commercial</span
            ><i data-feather="more-horizontal"></i>
          </li>
         
          <li class="nav-item" v-if="testRole('gestion des articles')">
            <router-link class="d-flex align-items-center" to="/produits">
              <i class="mdi mdi-package icone-layout"> </i>

              <span class="menu-title text-truncate">Articles </span>
            </router-link>
          </li>

          <li class="nav-item" v-if="testRole('gestion des clients')">
            <router-link class="d-flex align-items-center" to="/getclients">

              <i class="mdi mdi-human-handsdown icone-layout"> </i><span class="menu-title text-truncate">Clients</span>
            </router-link>
          </li>
          

          <li class="nav-item" v-if="testRole('gestion des commandes')">
            <router-link class="d-flex align-items-center" to="/commandes">
              <i class="mdi mdi-package-variant-closed icone-layout"> </i>

              <span class="menu-title text-truncate">Commandes</span>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="testRole('gestion des demandes_echantillon')"
          >
            <router-link
              class="d-flex align-items-center"
              to="/demandeechantillon"
            >
              <i class="mdi mdi-flask icone-layout"> </i>

              <span class="menu-title text-truncate">Demande échantillon</span>
            </router-link>
          </li>


          <li
            class="nav-item"
            v-if="user.role='web'"


          >
            <router-link
              class="d-flex align-items-center"
              to="/annonces"
            >
              <i class="mdi mdi-bullhorn-outline icone-layout"> </i>

              <span class="menu-title text-truncate">Marketings</span>
            </router-link>
          </li>

          <li class="nav-item"></li>


          <li class="navigation-header" v-if="testRole('gestion des zones')">
            <span data-i18n="Apps &amp; Pages">Gestion de zones</span
            ><i data-feather="more-horizontal"></i>
          </li>

          <li class="nav-item" v-if="testRole('gestion des zones')">
            <router-link class="d-flex align-items-center" to="/getzones">
              <i class="mdi mdi-map-marker-radius icone-layout"> </i
              ><span class="menu-title text-truncate">liste des zones</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="testRole('gestion des zones')">
            <router-link class="d-flex align-items-center" to="/showzones">
              <i class="mdi mdi-map-marker-radius icone-layout"> </i
              ><span class="menu-title text-truncate">Mapping Zone</span>
            </router-link>
          </li>

          <li
            class="navigation-header"
            v-if="
              testRole('gestion des emplacements') ||
              testRole('gestion des articles/stocks') ||
              testRole('gestion des demande_stocks')
            "
          >
            <span data-i18n="Apps &amp; Pages">Gestion de stocks</span
            ><i data-feather="more-horizontal"></i>
          </li>
          <li class="nav-item" v-if="testRole('gestion des emplacements')">
            <router-link class="d-flex align-items-center" to="/emplacements">
              <i class="mdi mdi-sitemap icone-layout"> </i
              ><span class="menu-title text-truncate">Emplacements</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="testRole('gestion des magasins')">
            <router-link class="d-flex align-items-center" to="/getmagasins">
              <i class="mdi mdi-server icone-layout"> </i
              ><span class="menu-title text-truncate">Dépot magasin</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="testRole('gestion des articles/stocks')">
            <router-link class="d-flex align-items-center" to="/stocks">
              <i class="mdi mdi-format-horizontal-align-left icone-layout"> </i
              ><span class="menu-title text-truncate">Articles/stocks</span>
            </router-link>
          </li>
          
          <li class="nav-item" v-if="testRole('gestion des demande_stocks')">
            <router-link
              class="d-flex align-items-center"
              to="/demandecommandes"
            >
              <i class="mdi mdi-format-rotate-90 icone-layout"> </i
              ><span class="menu-title text-truncate">Demmande/Articles</span>
            </router-link>
          </li>
    
          <li class="nav-item" v-if="testRole('gestion des visites')">
            <router-link class="d-flex align-items-center" to="/visites">
              <i class="mdi mdi-medical-bag icone-layout"> </i
              ><span class="menu-title text-truncate">Liste des visites</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="testRole('gestion des visites')">
            <router-link class="d-flex align-items-center" to="/calendrier">
              <i class="mdi mdi-calendar-check icone-layout"> </i
              ><span class="menu-title text-truncate">Calendrier</span>
            </router-link>
          </li>

          <li class="navigation-header" v-if="testRole('gestion des marketings')">
            <span data-i18n="Apps &amp; Pages">Animatrice</span>
          </li>
          <li class="nav-item" v-if="testRole('gestion des marketings')">
            <router-link class="d-flex align-items-center" to="/marketings">
              <i class="mdi mdi-calendar-check icone-layout"> </i
              >
              <span class="menu-title text-truncate" data-i18n="Email"
                >Liste des plannings</span
              >
            </router-link>
          </li>

          <li class="nav-item" v-if="testRole('gestion des marketings')">
            <router-link
              class="d-flex align-items-center"
              to="/calendrieranimatrice"
            >
            <i class="mdi mdi-calendar-check icone-layout"> </i
              ><span class="menu-title text-truncate" data-i18n="Email"
                >Calendrier Animatrice</span
              >
            </router-link>
          </li>



          <li
            class="nav-item"
            v-if="user.role='web'"

          >
            <router-link
              class="d-flex align-items-center"
              to="/audit"
            >
              <i class="mdi mdi-office-building-cog-outline icone-layout"> </i>

              <span class="menu-title text-truncate">Inventaire</span>
            </router-link>
          </li>

       
          <li
            class="navigation-header"
            v-if="testRole('gestion des formations')"
          >
            <span data-i18n="Apps &amp; Pages">Evenements</span>
          </li>
          <li class="nav-item">
            <router-link class="d-flex align-items-center"  to="/encours1">
              <i class="mdi mdi-keyboard-close icone-layout"> </i
              ><span class="menu-title text-truncate"
                >Liste des évenements</span
              >
            </router-link>
          </li>

          <li
            class="navigation-header"
            v-if="testRole('gestion des formations')"
          >
            <span data-i18n="Apps &amp; Pages">Gestion des formations</span>
          </li>
          <li class="nav-item" v-if="testRole('gestion des formations')">
            <router-link class="d-flex align-items-center" to="/formations">
              <i class="mdi mdi-book-open-page-variant icone-layout"> </i
              ><span class="menu-title text-truncate"
                >Liste des formations</span
              >
            </router-link>
          </li>

          <li
            class="navigation-header"
            v-if="testRole('gestion des roles')||testRole('gestion des utilisateurs')||testRole('gestion des groupes')"
          >
            <span data-i18n="Apps &amp; Pages">Paramétres</span>
          </li>
          <li class="nav-item" v-if="testRole('gestion des objectifs')">
            <router-link class="d-flex align-items-center" to="/objectifs">
              <i class="mdi mdi-message-draw icone-layout"> </i>

              <span class="menu-title text-truncate">Objectifs</span>
            </router-link>
          </li>

          <li class="nav-item" >
            <router-link class="d-flex align-items-center" to="/getunites">
              <i class="mdi mdi-webhook icone-layout"> </i>

              <span class="menu-title text-truncate">Gestion unité</span>
            </router-link>
          </li>


          <li class="nav-item" v-if="testRole('gestion des roles')">
            <router-link class="d-flex align-items-center" to="/getrole">
              <i class="mdi mdi-security icone-layout"> </i>

              <span class="menu-title text-truncate">Roles</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="testRole('gestion des utilisateurs')">
            <router-link class="d-flex align-items-center" to="/users">
              <i class="mdi mdi-account icone-layout"> </i>

              <span class="menu-title text-truncate">Utilisateurs</span>
            </router-link>
          </li>
          <li class="nav-item" 
      >
            <router-link class="d-flex align-items-center " to="/encours" >
              <i class="mdi mdi-format-align-center icone-layout"> </i>
              <span class="menu-title text-truncate" data-i18n="Dashboards"
                >Form Builder
              </span>
            </router-link>
          </li>
          

          <li class="nav-item" v-if="testRole('gestion des groupes')">
            <router-link class="d-flex align-items-center" to="/getgroups">
              <i class="mdi mdi-account-multiple icone-layout"> </i>

              <span class="menu-title text-truncate">groupes</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Main Menu-->

    <!-- BEGIN: Content-->
    <div class="app-content content">
      <router-view></router-view>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>

    <!-- BEGIN: Footer-->
    <!-- <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0"><span class="float-md-left d-block d-md-inline-block mt-25">COPYRIGHT &copy; 2021<a class="ml-25" href="https://1.envato.market/pixinvent_portfolio" target="_blank">Pixinvent</a><span class="d-none d-sm-inline-block">, All rights Reserved</span></span><span class="float-md-right d-none d-md-block">Hand-crafted & Made with<i data-feather="heart"></i></span></p>
    </footer> -->
    <button class="btn btn-primary btn-icon scroll-top" type="button">
      <i data-feather="arrow-up"></i>
    </button>
  </div>
</template>
<script>

import { HTTP } from "@/axios";

export default {
  name: "app-header",
  data() {
    return {
      user: [],
      permissions: [],
      objectif: "",
      client: "",
    };
  },

  created() {
    this.getuser();

  },
  mounted() {

  },
  methods: {
    testRole(data) {
      var result = false;

      const isFound = this.permissions.some((element) => {
        if (element.titre === data) {
          result = true;
        }
      });

      return result;
    },

    getuser() {
      var token = localStorage.getItem("token");
      
        // axios.get("http://localhost:3010/api/auth/getuser/" + token)
          HTTP
            .get("auth/getuser/" + token)
        .then((response) => {
          this.user = response.data;
          this.permissions = this.user.role_responsable.permissions;
        })
        .then((err) => console.log(err));
    },
    testObject(object) {
      return object;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/");
    },
  },
};
</script>